import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Download">
    <section className="section-download">

      <h5><a href="https://play.google.com/store/apps/details?id=me.parakh.edu" target="_blank" rel="noopener noreferrer">click here to download</a></h5>


    </section>
  </Layout>
);

export default Page;

